@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-radial-gradient {
  background: radial-gradient(
    circle,
    rgba(238, 177, 191, 0.2) 5%,
    rgba(212, 45, 171, 0.2) 25%,
    rgba(77, 29, 99, 0.2) 50%,
    rgba(3, 2, 67, 0.2) 80%
  );
}